<template>
  <footer id="footer" class="bg-light mt-0">
    <div class="footer-copyright footer-copyright-border-top bg-dark-5 py-5 text-center text-md-left">
        <div class="container">
            <div class="row align-items-center justify-content-md-between">
                <div class="col-12 col-lg-8 order-3 order-lg-1">
                  ㈜원성글로벌<br>
사업자등록번호 : 596-88-02642 <span class="px-3">|</span>   대표이사 : 장주현<span class="px-3">|</span> T. 1533-4178 <br>
(본점)  (18625) 경기도 화성시 향남읍 발안로 679-14<br>
(서울지사 및 개발연구소)  (07549) 서울특별시 강서구 양천로 532, 605~606호 (더리브 아너비즈타워)
<br>copyright ⓒ Wonsung Global Co.Ltd. All Rights Reserved.
                </div>
                <!-- <div class="col-12 col-lg-2 text-center order-1 order-lg-1">
                    <router-link to="/"><img src="@/assets/img/logo-small-light.png" height="70" alt="footer" /></router-link>
                </div>	 -->
                <div class="col-12 col-lg-4 text-center text-lg-right order-2 order-lg-2 mb-2 mb-lg-0 d-flex flex-wrap justify-content-center justify-content-md-end mb-3">	
                    <div class=" my-3">
                      <router-link to="/news" class="link-underline-dark mr-3">News</router-link>
                      <!-- <router-link  :to="{
                          name:'Terms', query:{tab_idx: 0}
                        }" class="link-underline-dark mr-3">전자지급결제대행서비스 이용약관</router-link> -->
                      <router-link :to="{
                          name:'Terms', query:{tab_idx: 0}
                        }" class="link-underline-dark ">개인정보처리방침</router-link>
                    </div>
                    <div class="text-lg-right">
                      
                    <!-- <div class="gnb ml-3 my-2">
                      <div class="familysite-select-wrapper">
                        <select id="familysite" class="familysite-select fa-pull-right" onchange="window.open(value,'_blank');">
                          <option selected disabled>Family Site</option>
                          <option value="/https://home.thegoodpay.co.kr">TheGoodPay</option>
                        </select>
                      </div>
                    </div>
                      <select class="form-control rounded max-w-px-200 fa-pull-right " name="" id="" onchange="window.open(value,'_blank');">
                        <option selected disabled>Family Site</option>
                        <option value="/pay">TheGoodPay</option>
                      </select> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>
<style scoped>
#footer{
  font-size: 14px;
}
.gnb {
  background-color: #2E3237; /* 푸터 색상과 일치시키기 위해 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.familysite-select-wrapper {
  margin-left: auto; /* 선택 요소를 오른쪽으로 정렬 */
}

.familysite-select {
  background-color: #40454B; /* 어두운 색상으로 배경 설정 */
  color: #FFFFFF; /* 텍스트 색상 흰색으로 */
  border: 1px solid #5A5F65; /* 테두리 색상 */
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.familysite-select:hover {
  background-color: #5A5F65; /* 마우스를 올렸을 때 배경 색상 */
}

.familysite-select option {
  background-color: #2E3237; /* 드롭다운 항목의 배경 색상 */
  color: #FFFFFF; /* 드롭다운 항목의 텍스트 색상 */
}

</style>